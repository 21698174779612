import React, { useContext, useEffect, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { ShopContext } from "../context/Shop-context";
import RelatedProducts from "../components/RelatedProducts";
import { PRODUCTS } from "../Product";

const ProductDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [quantity, setQuantity] = useState(1);
  const { id } = useParams();
  const { addToCart } = useContext(ShopContext);
  const selectedProduct = PRODUCTS.find(
    (product) => product.id === parseInt(id)
  );

  if (!selectedProduct) {
    return <div>Product not found</div>;
  }

  const { img, title, price, details } = selectedProduct;
  return (
    <div className="w-full bg-white md:h-[1600px] md:px-20 px-3">
      <div className="md:w-[1280px] md:h-[47px] w-[358px]  bg-[#F2F2F3] relative top-1 ">
        <div className="  ">
          <ul className="flex py-3 px-3 ">
            <Link className="flex items-center gap-[2px] font-inter text-[12px] text-[#666666]">
              Home
              <BsChevronRight size={12} />
            </Link>
            <Link className="flex items-center gap-[2px] ml-2 font-inter text-[12px] text-[#666666]">
              Products
              <BsChevronRight size={12} />
            </Link>
            <Link className="flex items-center ml-2 font-inter text-[12px] text-[#1A1A1A]">
              Chair
            </Link>
          </ul>
        </div>
      </div>
      <div className="grid md:grid-cols-2 grid-cols-1 md:w-[1280px] md:h-[500px] gap-0 bg-white my-4">
        {/* for big screen size */}
        <div className="w-[587px] h-[500px] hidden md:block">
          <div className="flex gap-4">
            <div className="grid grid-rows-4 w-[71px] h-[332px] ">
              <img src={img} alt="" width={"70px"} />
              <img src={img} alt="" width={"70px"} />
              <img src={img} alt="" width={"70px"} />
              <img src={img} alt="" width={"70px"} />
            </div>
            <img src={img} alt="" width={"400px"} />
          </div>
        </div>
        {/* for mobbile */}
        <div className="w-[358px] h-[570px] md:hidden block">
          <div className="block gap-10">
            <img src={img} alt="" width={"400px"} />
            <div className="grid grid-cols-4 w-[332px] h-[71px] mt-4 ">
              <img src={img} alt="" width={"70px"} />
              <img src={img} alt="" width={"70px"} />
              <img src={img} alt="" width={"70px"} />
              <img src={img} alt="" width={"70px"} />
            </div>
          </div>
        </div>

        <div className="md:w-[650px] md:h-[474px] w-[358px] h-[546px]  relative md:right-2">
          <div className="md:w-[630px] md:h-[134px] w-[358px] h-[160px]  md:px-3 md:py-3 flex flex-col">
            <span className="text-[#010101] font-inter font-semibold text-[24px]">
              {title}
            </span>
            <span className="text-[#010101] font-inter font-semibold text-[18px]">
              ₦ {price}
            </span>
            <span className="text-[#4D4D4D] w-[500px] font-inter font-normal   text-[12px] pt-1">
              {details}
            </span>
          </div>
          <div className="md:px-3 py-5">
            <span className="text-[#010101] font-inter font-medium text-[16px]">
              Quality
            </span>
            <div className="w-[168px] h-[42px] border border-[#E6E6E6] rounded-lg flex items-center justify-center gap-8 my-3">
              <button onClick={() => setQuantity(Math.max(quantity - 1, 1))}>
                -
              </button>
              <span>{quantity}</span>
              <button onClick={() => setQuantity(quantity + 1)}>+</button>
            </div>
          </div>

          <div className="md:w-[629px]  w-[358px] h-[48px] flex  md:gap-1 md:px-3 ">
            <Link to="/shop">
              <button className="md:w-[306px] h-[48px] w-[171px] border rounded border-[#E6E6E6] text-[#010101] font-inter font-semibold text-[14px]">
                SHOP NOW
              </button>
            </Link>
            <button
              className="md:w-[306px] h-[48px] w-[171px] rounded bg-[#0E7E80] text-[#FCFCFD] focus:outline-none font-inter font-semibold text-[14px] ml-2"
              onClick={() => addToCart(id)}
            >
              ADD TO CART
            </button>
            <button></button>
          </div>

          <div className="w-[629px] h-[125px] md:w-[358px] md:h-[163px] md:px-3 md:py-3 py-6">
            <span className="text-[14px] text-[#010101] font-semibold font-inter uppercase">
              Product Details
            </span>
            <br />
            <p className="w-[358px] md:w-[469px]  text-[12px] font-normal text-[#181513] leading-[20px] pt-2 font-urbanist">
              Lorem ipsum dolor sit amet consectetur. Vitae risus cum ultrices
              at. Id ut odio convallis odio egestas. Dignissim tristique mauris
              pulvinar non. Sed dui in auctor aenean felis. Enim semper libero
              morbi sollicitudin. Vestibulum orci ut hac augue. Interdum eget
              odio tortor cursus.
            </p>
          </div>
        </div>
      </div>

      <div className="py-8">
        <h3 className="font-inter text-[32px] text-[#010101] font-medium ">
          Relatable product
        </h3>

        <RelatedProducts />
      </div>

      <div class="hero-image bg-cover bg-center bg-no-repeat md:h-[320px] md:w-[1280px] w-[358px] h-[320px] relative  flex rounded-lg my-8 md:mt-12 ">
        <div className="md:w-[430px] md:h-[250px] w-[328px]  h-[134px] md:py-6  mx-[auto] my-[auto]">
          <h1 className="text-[#fff] font-urbanist md:px-2 px-1 uppercase md:leading-[40px]  font-semibold text-[23px] md:text-[30px] text-center">
            build your dream <br /> room to real life now !
          </h1>

          <div className="flex justify-center items-center my-4">
            <button className="w-[200PX] h-[48px] bg-[#0E7E80] rounded-[4px] text-[14px] font-semibold text-[#FCFCFD]">
              EXPLORE MORE
            </button>
            <BsChevronRight
              size={16}
              color="white"
              className="relative top-[0.5px] right-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
