import img1 from "../src/assets/images/image1.png";
import img2 from "../src/assets/images/image2.png";
import img3 from "../src/assets/images/image3.png";
import img4 from "../src/assets/images/image5.png";
import img5 from "../src/assets/images/image6.png";
import img6 from "../src/assets/images/image7.png";
import img7 from "../src/assets/images/image8.png";
import img8 from "../src/assets/images/item1.png";
import img9 from "../src/assets/images/item2.png";
import img10 from "../src/assets/images/item3.png";
import img11 from "../src/assets/images/item4.png";
import img12 from "../src/assets/images/item5.png";
import img13 from "../src/assets/images/item6.png";
import img14 from "../src/assets/images/item7.png";
import img15 from "../src/assets/images/item8.png";

export const PRODUCTS = [
  {
    id: 1,
    img: img1,
    title: "Chair",
    price: 10000,
    category: "Chair",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },

  {
    id: 2,
    img: img2,
    title: "Chair",
    price: 20000,
    category: "chair",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 3,
    img: img3,
    title: "Table",
    price: 30000,
    category: "table",
    details:
      "Lorem ipsum. dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 4,
    img: img4,
    title: "Sofa",
    price: 40000,
    category: "sofa",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 5,
    img: img5,
    title: "Chair",
    price: 20000,
    category: "chair",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 6,
    img: img6,
    title: "Table",
    price: 50000,
    category: "table",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 7,
    img: img7,
    title: "Table",
    price: 50000,
    category: "table",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },

  {
    id: 8,
    img: img8,
    title: "Bed",
    price: 20000,
    category: "bed",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 9,
    img: img9,
    title: "Bed",
    price: 10000,
    category: "bed",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 10,
    img: img10,
    title: "Office Furniture",
    price: 30000,
    category: "office-furniture",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 11,
    img: img11,
    title: "Office Furniture",
    price: 40000,
    category: "furniture",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 12,
    img: img12,
    title: "Sofa",
    price: 30000,
    category: "sofa",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 13,
    img: img13,
    title: "Sofa",
    price: 20000,
    category: "sofa",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 14,
    img: img14,
    title: "Console",
    price: 30000,
    category: "console",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
  {
    id: 15,
    img: img15,
    title: "Console",
    price: 50000,
    category: "console",
    details:
      "Lorem ipsum dolor sit amet consectetur. Tellus aliquam tellus quis tortor pellen tesque. Ac risus enim urna quis sagittis pellentesque semper. In porttitor in pretium proin morbi. Lectus nibh amet venenatis odio odio. Ultrices quam non aenean tristique.Sollicitudin aliquam aliquet mi.",
  },
];
