import React, { useEffect, useState } from "react";

import { BsChevronRight } from "react-icons/bs";

import { PRODUCTS } from "../Product";
import ProductItem from "../components/ProductItem";

import Categories from "../components/Categories";

const Shop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedSort, setSelectedSort] = useState("none");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    // Filter products based on selectedCategory and searchQuery
    let filteredProducts = PRODUCTS.filter((product) => {
      const categoryMatch =
        selectedCategory === "all" ||
        product.category.toLowerCase() === selectedCategory;
      const searchMatch = product.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      return categoryMatch && searchMatch;
    });

    if (selectedSort === "priceLowToHigh") {
      filteredProducts.sort((a, b) => a.price - b.price);
    } else if (selectedSort === "priceHighToLow") {
      filteredProducts.sort((a, b) => b.price - a.price);
    }

    return filteredProducts;
  };

  const handleSearchIconClick = () => {
    const filteredProducts = handleSearch();
    // Update state or perform any other actions with the filtered products
    console.log(filteredProducts);
  };

  const handleSortChange = (sortOption) => {
    setSelectedSort(sortOption);
  };

  return (
    <div className="bg-white">
      <div class="w-full bg-image  md:h-[260px] h-[404px] bg-[center] bg-[cover] relative">
        <div class="absolute inset-0 bg-black opacity-50"></div>

        <div class="absolute inset-0 flex  items-center justify-center text-white text-center">
          <h1 className="text-[#fff] md:w-[859px]  md:h-[144px] w-[324px] h-[192px] uppercase md:leading-[72px] font-bold text-[40px] md:text-[55px] font-vastego ">
            Discover our amazing furniture collection
          </h1>
        </div>
      </div>

      <div className="flex gap-10 px-3 md:px-16 md:py-10 py-6 ">
        <div className="hidden md:block">
          <Categories
            onCategoryChange={handleCategoryChange}
            onSortChange={handleSortChange}
          />
        </div>

        <div className="w-[970px]">
          <div className="flex justify-center items-center">
            <div className="hidden relative md:flex">
              <input
                type="text"
                class="py-2 w-[900px] pl-3 border border-r-0 rounded-r-none border-[#E6EDFF] bg-[#FCFCFD] rounded-lg  focus:outline-none "
                placeholder=" Search here"
                value={searchQuery}
                onChange={handleSearchChange}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearchIconClick();
                  }
                }}
              />
              <div
                class="flex w-8 h-12 justify-center rounded-l-none border-l-0 items-center pointer-events-none border border-[#E6EDFF] bg-[#FCFCFD] rounded-lg  focus:outline-none "
                onClick={handleSearchIconClick}
              >
                <svg
                  class="w-4 h-4 text-[#666666]"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  cursor={PointerEvent}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-4.35-4.35"
                  ></path>
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16z"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center pt-4">
            <p className="font-poppins font-semibold text-[16px]   text-[#010101] pl-2">
              All Products
            </p>
            <div className="flex items-center gap-2">
              <p className="font-inter font-normal text-[16px]  text-[#4D4D4D]">
                Sort by:
              </p>
              <select
                name=""
                id=""
                className="font-inter font-medium text-[16px]"
              >
                <option value="">Most Popular</option>
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 py-10">
            {handleSearch().map((product) => (
              <ProductItem data={product} key={product.id} />
            ))}
          </div>
        </div>
      </div>
      <div class="hero-image bg-cover bg-center bg-no-repeat md:h-[320px] md:w-[1300px] w-[358px] h-[320px] relative  flex rounded-lg md:mt-12 mx-20 my-6">
        <div className="md:w-[430px] md:h-[250px] w-[328px]  h-[134px] md:py-6  mx-[auto] my-[auto]">
          <h1 className="text-[#fff] font-urbanist md:px-2 px-1 uppercase md:leading-[40px]  font-semibold text-[23px] md:text-[30px] text-center">
            build your dream <br /> room to real life now !
          </h1>

          <div className="flex justify-center items-center my-4">
            <button className="w-[200PX] h-[48px] bg-[#0E7E80] rounded-[4px] text-[14px] font-semibold text-[#FCFCFD]">
              EXPLORE MORE
            </button>
            <BsChevronRight
              size={16}
              color="white"
              className="relative top-[0.5px] right-8"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shop;
