import React from "react";
import { BsChevronRight } from "react-icons/bs";
const Hero = () => {
  return (
    <div class="w-full hero h-[600px]  bg-center  bg-cover   relative">
      <div class="absolute inset-0 bg-black opacity-50"></div>

      <div class="absolute inset-0 flex flex-col items-center justify-center text-white text-center">
        <h1 className="text-[#fff] md:w-[850px] w-[358px] h-[192px] md:h-[216px] uppercase md:leading-[72px] leading-[48px] font-vastego font-normal md:font-bold md:text-[55px] text-[40px] ">
          Unveiling Your Dream Space – Shop Furniture Online Now!
        </h1>
        <div className=" mt-4 px-20">
          <p className="font-inter md:w-[672px] md:h-[75px] w-[358px] h-[138px] font-medium text-[17px] md:text-[17px] text-white text-center">
            Discover top-notch, locally crafted Upholsteries, Furniture for
            Homes & Offices, and quality Aluminum Doors – all in one convenient
            online emporium. Elevate your space with our handcrafted excellence.
          </p>
        </div>
        <div className="mt-5 flex items-center justify-center">
          <button
            href="#"
            class="bg-[#0E7E80] hover:bg-[#1e4c4e] w-[195px] h-[48px] font-inter rounded-[4px] text-[14px] font-semibold   py-2 px-4  transition duration-300 ease-in-out transform hover:scale-105"
          >
            VIEW ALL
          </button>

          <BsChevronRight
            size={16}
            color="white"
            className="relative top-[1px] right-[54px]"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
