import React from "react";
import { Link } from "react-router-dom";

const ProductItem = (props) => {
  const { id, img, title, price, details  } = props.data;
  return (
    <Link to={`/productdetails/${id}`}>
      <img src={img} alt="" className="w-[358px] h-[368px] md:w-[307px]"/>
      <p className="font-semibold text-[21px] text-[#010101] font-inter pt-4 pl-1">
        {title}
      </p>

      <span className="font-medium text-[16px] pl-1 text-[#4D4D4D] font-inter">
        ₦{price}
      </span>
    </Link>
  );
};

export default ProductItem;
