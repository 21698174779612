import React from "react";
import { AiFillStar } from "react-icons/ai";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const datas = [
  {
    id: 1,

    review:
      "  Experience the epitome of style and comfort with our beautifully crafted wooden armchair that seamlessly blends timeless elegance with cozy relaxation.",
    name: "    Marvin McKinney",
    star: <AiFillStar color="#052D2E" />,
  },
  {
    id: 2,

    review:
      "  Experience the epitome of style and comfort with our beautifully crafted wooden armchair that seamlessly blends timeless elegance with cozy relaxation.",
    name: "    Marvin McKinney",
    star: <AiFillStar color="#052D2E" />,
  },
  {
    id: 3,

    review:
      "  Experience the epitome of style and comfort with our beautifully crafted wooden armchair that seamlessly blends timeless elegance with cozy relaxation.",
    name: "    Marvin McKinney",
    star: <AiFillStar color="#052D2E" />,
  },
  {
    id: 4,

    review:
      "  Experience the epitome of style and comfort with our beautifully crafted wooden armchair that seamlessly blends timeless elegance with cozy relaxation.",
    name: "    Marvin McKinney",
    star: <AiFillStar color="#052D2E" />,
  },
  {
    id: 5,

    review:
      "  Experience the epitome of style and comfort with our beautifully crafted wooden armchair that seamlessly blends timeless elegance with cozy relaxation.",
    name: "    Marvin McKinney",
    star: <AiFillStar color="#052D2E" />,
  },
  {
    id: 6,

    review:
      "  Experience the epitome of style and comfort with our beautifully crafted wooden armchair that seamlessly blends timeless elegance with cozy relaxation.",
    name: "    Marvin McKinney",
    star: <AiFillStar color="#052D2E" />,
  },
];
const ReviewSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    centerMode: true,
    centerPadding: "50px",
    focusOnSelect: true,
    initialSlide: 0,
    lazyLoad: "ondemand",
    swipe: true,
  };
  return (
    <>
      <Slider {...settings} className="hidden md:block">
        {datas.map((data) => (
          <div key={data.id}>
            <div className="w-[570px] h-[179px] border border-[#E6E6E6] rounded-lg px-3 py-3">
              <div className="flex justify-center">
                {data.star}
                {data.star}
                {data.star}
                {data.star}
                {data.star}
              </div>
              <p className="text-center font-inter text-[16px] font-normal text-[#4D4D4D] pt-3">
                {data.review}
              </p>

              <p className=" text-center font-urbanist text-[16px] font-semibold text-[#1A1A1A] pt-3">
                {data.name}
              </p>
            </div>
          </div>
        ))}
      </Slider>


      <div className="md:hidden grid grid-rows-3 gap-8">
      {datas.map((data) => (
          <div key={data.id}>
            <div className="w-[358px] h-[200px] border border-[#E6E6E6] rounded-lg px-3 py-3">
              <div className="flex justify-center">
                {data.star}
                {data.star}
                {data.star}
                {data.star}
                {data.star}
              </div>
              <p className="text-center font-inter text-[16px] font-normal text-[#4D4D4D] pt-3">
                {data.review}
              </p>

              <p className=" text-center font-urbanist text-[16px] font-semibold text-[#1A1A1A] pt-3">
                {data.name}
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ReviewSlider;
