import React, { useContext, useEffect, useState } from "react";
import { toast, Toaster } from "react-hot-toast";
import { ShopContext } from "../context/Shop-context";
// import { CartItem } from "./cart-item";

import { PRODUCTS } from "../Product";
import CartItem from "./CartItem";
import { Link } from "react-router-dom";

const Cart = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [isCartEmpty, setIsCartEmpty] = useState(false);
  const { cartItems } = useContext(ShopContext);

  const overallAmount = Object.entries(cartItems).reduce(
    (total, [itemId, quantity]) => {
      const item = PRODUCTS.find((product) => product.id === Number(itemId));
      if (item) {
        return total + item.price * quantity;
      }
      return total;
    },
    0
  );

  return (
    <div className="md:px-20 md:py-8 px-3 py-1 bg-white w-full">
      <h1 className="font-inter font-semibold text-[32px] md:text-[24px] text-[#010101] md:pt-4">
        MY CART
      </h1>

      <div className="md:w-[900px] h-[34px] w-[358px]  bg-[#F2F2F3] grid md:grid-cols-3 md:gap-6 md:px-6 px-3 py-1 mt-2">
        <div>
          <p className="text-[#010101] font-inter text-[14px] font-medium">
            Products
          </p>
        </div>
        <div className="hidden md:block">
          <p className="text-[#010101] font-inter text-[14px] font-medium pl-20">
            Quantity
          </p>
        </div>
        <div className="hidden md:block">
          <p className="text-[#010101] font-inter text-[14px] font-medium pl-24">
            Subtotal
          </p>
        </div>
      </div>

      <div className="grid md:grid-cols-2 grid-cols-1 gap-10">
        <div className="pt-2">
          {Object.values(cartItems).some((item) => item > 0) ? (
            PRODUCTS.map((product) => {
              if (cartItems[product.id] !== 0) {
                return <CartItem data={product} />;
              }
            })
          ) : (
            <p>Your cart is empty</p>
          )}
        </div>
        {isCartEmpty && toast.success("Your cart is empty")}
        <Toaster />

        <div className=" w-[358px] h-[306px]  relative border border-[#F2F2F3] rounded-lg md:left-52 px-5 my-5  md:top-[-60px]">
          <div className="w-[310px] h-[200px] py-2 ">
            <p className="font-semibold text-[16px] font-inter text-[#010101]">
              Cart Total
            </p>

            <div className="grid grid-rows-3 ">
              <div className="flex justify-between items-center">
                <p className="font-normal text-[12px] font-inter text-[#666666]">
                  Subtotal
                </p>
                <p className="font-semibold text-[16px] font-inter text-[#010101]">
                  ₦{overallAmount}
                </p>
              </div>
              <div className="h-[48px] border border-[#E6E6E6] rounded flex justify-between px-3 items-center">
                <label className="font-normal text-[12px] font-inter text-[#666666]">
                  Your Voucher
                </label>
                <button className="font-semibold text-[12px] font-inter text-[#010101]">
                  Apply
                </button>
              </div>
              <div className="h-[48px] border border-[#E6E6E6] rounded flex justify-between px-3 items-center mt-2">
                <label className="font-normal text-[12px] font-inter text-[#666666]">
                  Grand Total
                </label>
                <button className="font-semibold text-[12px] font-inter text-[#010101]">
                  ₦ 40,000
                </button>
              </div>
            </div>

            <Link to="/login">
              <button className="w-full h-[42px] rounded bg-[#0E7E80] text-[#FCFCFD] font-inter font-semibold text-[14px] mt-5">
                Sign in to proceed
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
