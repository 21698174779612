import React, { createContext, useState } from "react";
import { PRODUCTS } from "../Product";

export const ShopContext = createContext(null);

const getDefaultCart = () => {
  let cart = {};
  for (let i = 1; i < PRODUCTS.length + 1; i++) {
    cart[i] = 0;
  }
  return cart;
};

const ShopContextProvider = (props) => {
  const [cartItems, setCartItems] = useState(getDefaultCart());
  const [cartItemCount, setCartItemCount] = useState(0);
  const [isItemAddedToCart, setIsItemAddedToCart] = useState(false);

  const increaseQuantity = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    setCartItemCount((prev) => prev + 1);
  };

  const getTotalItemAmount = (itemId) => {
    const itemInfo = PRODUCTS.find((product) => product.id === itemId);
    return itemInfo.price * cartItems[itemId];
  };

  const resetIsItemAddedToCart = () => {
    setTimeout(() => {
      setIsItemAddedToCart(false);
    }, 3000); // Reset after 3 seconds (adjust as needed)
  };

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] + 1 }));
    setCartItemCount((prev) => prev + 1);
    setIsItemAddedToCart(true);
    resetIsItemAddedToCart();
  };
  const decreaseQuantity = (itemId) => {
    if (cartItems[itemId] > 0) {
      setCartItems((prev) => ({ ...prev, [itemId]: prev[itemId] - 1 }));
      setCartItemCount((prev) => prev - 1);
    }
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: 0 }));
    setCartItemCount((prev) => prev - cartItems[itemId]);
  };

  const resetCartIcon = () => {
    setIsItemAddedToCart(false);
  };

  const contextValue = {
    cartItems,
    addToCart,
    setCartItems,
    removeFromCart,
    resetCartIcon,
    increaseQuantity,
    cartItemCount,
    setCartItemCount,
    isItemAddedToCart,
    setIsItemAddedToCart,
    decreaseQuantity,
    getTotalItemAmount,
  };
 

  return (
    <ShopContext.Provider value={contextValue}>
      {props.children}
    </ShopContext.Provider>
  );
};

export default ShopContextProvider;
