import React, { useState } from "react";
import img1 from "../assets/images/logo.svg";
import img2 from "../assets/images/img7.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { passwordReset } from "../schemas/validation";
const PasswordReset = () => {
  const initialValues = {
    email: "",
  };

  const onSubmit = () => {
    alert("form succesfully submitted");
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: passwordReset,
    onSubmit,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <div className="w-full md:bg-[#404040] md:h-[100vh]">
      <div className="bg-white md:w-[1000px] h-[500px]  relative top-6 md:left-48  grid md:grid-cols-2 grid-cols-1">
        <div className="bg-red-700 hidden md:block">
          <img src={img2} alt="" className="" />
        </div>
        <div className="bg-white md:px-12 px-4 md:pt-32 pt-2">
          <Link to="/">
            <img src={img1} alt="logo" />
          </Link>

          <h3 className="text-[#010101] font-vastego font-semibold text-[30px] pt-5">
            Forget Password 🔒
          </h3>
          <p className="font-sans font-normal text-[14px] text-[#666666] py-2">
            No worries, we will send you a password reset link.
          </p>
          <div>
            <form action="" className="mt-1" onSubmit={handleSubmit}>
              <div className="py-1">
                <label
                  htmlFor="email"
                  className="font-sans font-medium text-[14px] text-[#010101]"
                >
                  Email Address{" "}
                  <span className="text-[#EF4444] font-semibold">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError("email")}
                  className="w-full pl-3 bg-[#FCFCFD] h-[48px] rounded-[4px] border border-[#E9EBED] my-2 focus:outline-[#E9EBED]"
                />
              </div>

              <button className="w-full h-[48px] bg-[#0E7E80] font-sans font-semibold text-[14px] text-[#FCFCFD] rounded mt-4">
                PROCEED
              </button>

              <div className="flex justify-center pt-4">
                <Link
                  to="/login"
                  className="font-sans text-[12px] font-normal text-[#010101]"
                >
                  Back to Login
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordReset;
