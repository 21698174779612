import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <div>Pages not found Erro 404</div>
      <p>
        Go back to <Link to="/">Homepage</Link>
      </p>
    </>
  );
};

export default NotFound;
