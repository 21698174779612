import React from "react";
import img1 from "../assets/images/image1.png";
import img2 from "../assets/images/image2.png";
import img3 from "../assets/images/image3.png";
import img4 from "../assets/images/image4.png";
import { BsChevronRight } from "react-icons/bs";

const RelatedProducts = () => {
  const Items = [
    {
      id: 1,
      img: img1,
      title: "Product Title",
      price: "20000",
    },

    {
      id: 2,
      img: img2,
      title: "Product Title",
      price: "20000",
    },
    {
      id: 3,
      img: img3,
      title: "Product Title",
      price: "20000",
    },
    {
      id: 4,
      img: img4,
      title: "Product Title",
      price: "20000",
    },
  ];

  return (
    <div className="grid md:grid-cols-4 grid-cols-1 gap-2  py-4">
      {Items.map((item) => (
        <div key={item.id}>
          <img src={item.img} alt="" className="w-[358px] h-[368px] md:[305px]"/>
          <p className="font-semibold text-[21px] text-[#010101] font-sans pt-4">
            {item.title}
          </p>

          <span className="font-medium text-[16px] text-[#4D4D4D] font-sans">
            ₦{item.price}
          </span>
        </div>
      ))}

      <div className="md:hidden flex justify-center my-1">
        <button className="w-[160px] h-[48px] bg-[#0E7E80] rounded-[4px] text-[14px] font-semibold text-[#FCFCFD] font-inter">
          VIEW ALL
        </button>
        <BsChevronRight
          size={16}
          color="white"
          className="relative top-[16px] right-8"
        />
      </div>
    </div>
  );
};

export default RelatedProducts;
