import React from "react";
import img1 from "../assets/images/image10.png";
import img2 from "../assets/images/image11.png";
import img3 from "../assets/images/image12.png";
import img4 from "../assets/images/image9.png";
import img5 from "../assets/images/logo.svg";
import image1 from "../assets/images/facebook.png";
import image2 from "../assets/images/twitter.png";
import image3 from "../assets/images/instagram.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <section className="bg-[#0E7E80] w-full md:h-[144px] h-[188px] md:px-16 md:py-10 py-6 px-3">
        <div className="grid md:grid-cols-4 grid-cols-2 md:gap-x-20 gap-x-10 gap-y-4  ">
          <div>
            <div className="flex gap-3 items-center">
              <div>
                {" "}
                <img src={img2} width={"48"} alt="" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[12px] text-white font-inter">
                  FAST DELIVERY
                </span>
                <span className="font-medium text-[12px] text-white font-inter md:w-[150px] md:h-[20px] w-[99px] h-[28px]">
                  Delivery in 24 hours max
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-3 items-center">
              <div>
                <img src={img4} width={"35px"} alt="" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[12px] text-white font-inter">
                  SAFE PAYMENT
                </span>
                <span className="font-medium text-[12px] text-white font-inter">
                  100% Secure Payment
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-3 items-center">
              <div>
                <img src={img1} width={"39px"} alt="" />
              </div>
              <div className="flex flex-col gap-1  md:ml-0 ml-3">
                <span className="font-medium text-[12px] text-white font-inter">
                  HELP CENTER
                </span>
                <span className="font-medium text-[12px] text-[#fff] font-inter">
                  Dedicated 24/7 Support
                </span>
              </div>
            </div>
          </div>
          <div>
            <div className="flex gap-3 items-center">
              <div>
                {" "}
                <img src={img3} width={39} alt="" />
              </div>
              <div className="flex flex-col gap-1">
                <span className="font-medium text-[12px] text-white font-inter">
                  CURATED ITEMS
                </span>
                <span className="font-medium text-[12px] text-white font-inter md:w-[150px] md:h-[20px] w-[99px] h-[28px]">
                  From hand picked sellers
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="w-full md:h-[184px] bg-white md:px-16 px-6 py-8 ">
        <div className="flex md:flex-row flex-col justify-between md:items-center py-6">
          <div>
            <Link to="/">
              <img src={img5} alt="logo" width={100} height={100} />
            </Link>
          </div>

          <div className="flex  md:gap-60 gap-32 md:pt-0 pt-6">
            <div>
              <span className="text-[#010101] font-inter font-medium text-[14px] ">
                INFO
              </span>
              <ul className="flex flex-col gap-2 pt-2">
                <Link
                  to=""
                  className="text-[#010101] font-inter font-normal text-[12px]"
                >
                  About
                </Link>
                <Link
                  to=""
                  className="text-[#010101] font-inter font-normal text-[12px]"
                >
                  Policy
                </Link>
                <Link
                  to=""
                  className="text-[#010101] font-inter font-normal text-[12px]"
                >
                  Term
                </Link>
              </ul>
            </div>
            <div>
              <span className="text-[#010101] font-inter font-medium text-[14px] ">
                Customer
              </span>
              <ul className="flex flex-col gap-2 pt-2">
                <Link
                  to=""
                  className="text-[#010101] font-inter font-normal text-[12px]"
                >
                  Return
                </Link>
                <Link
                  to=""
                  className="text-[#010101] font-inter font-normal text-[12px]"
                >
                  Shipping
                </Link>
                <Link
                  to=""
                  className="text-[#010101] font-inter font-normal text-[12px]"
                >
                  Payment
                </Link>
              </ul>
            </div>
          </div>
          <div className="relative md:left-[-50px] pt-6 md:pt-6">
            <div className="flex flex-col gap-1">
              <span className="text-[#010101] font-inter font-medium text-[16px] ">
              Shop 85 Amosun
Shopping complex itoku
Abeokuta Ogunstate
              </span>
              <span className="text-[#010101] font-inter font-medium text-[16px]">
              +234 9019302697
              </span>
            </div>
            <div className="flex gap-2 md:pt-3 py-6">
              <Link to="">
                <img src={image1} width={30} height={30} alt="" />
              </Link>
              <Link to="">
                <img src={image2} width={30} height={30} alt="" />
              </Link>
              <Link tp="">
                <img src={image3} width={30} height={30} alt="" />
              </Link>
            </div>

            <span className="md:hidden flex justify-center text-[12px] font-normal font-inter text-[#666666] ">
              © 2023 Furstore
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
