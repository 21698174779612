import React, { useContext, useEffect } from "react";
import { RiDeleteBinLine } from "react-icons/ri";
import { ShopContext } from "../context/Shop-context";
const CartItem = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { id, img, title, price } = props.data;
  const {
    cartItems,
    addToCart,
    removeFromCart,
    resetCartIcon,
    decreaseQuantity,
    getTotalItemAmount,
  } = useContext(ShopContext);

  const quantity = cartItems[id] || 0;
  const totalAmount = getTotalItemAmount(id);

  const handleDelete = () => {
    removeFromCart(id);
  };
  const handleDecrease = () => {
    decreaseQuantity(id);
    resetCartIcon(); // Reset cart icon
  };

  return (
    <>
      <div className="hidden md:block">
        <div className="w-[900px] h-[130px] bg-[#fff]  border-b border-[#F2F2F3] grid grid-cols-3 gap-10">
          <div className="flex gap-2">
            <img
              src={img}
              alt={title}
              width={"90px"}
              height={"90px"}
              className="object-contain"
            />
            <div className="flex flex-col py-6 gap-1">
              <span className="text-[#010101] font-inter text-[18px] font-medium">
                {title}
              </span>
              <span className="text-[#010101] font-inter text-[16px] font-normal">
                ₦{price}
              </span>
            </div>
          </div>
          <div className="ml-10 my-7">
            <div className="w-[168px] h-[42px] border border-[#E6E6E6] rounded-lg flex items-center justify-center gap-8">
              <button className="" onClick={handleDecrease}>
                -
              </button>
              <span>{quantity}</span>
              <button className="" onClick={() => addToCart(id)}>
                +
              </button>
            </div>
          </div>
          <div className="flex gap-10 items-center ml-6">
            <p className="text-[#010101] font-inter text-[20px] font-medium">
              ₦ {totalAmount}
            </p>
            <button className="w-[30px] h-[30px] bg-[#F8F8F8] flex items-center justify-center ml-6">
              <RiDeleteBinLine
                color="#FF1616"
                className="cursor-pointer"
                onClick={handleDelete}
              />
            </button>
          </div>
        </div>
      </div>

      <div className="md:hidden block">
        <div className="w-[358px] h-[130px] bg-[#fff]  border-b border-[#F2F2F3] mt-4">
          <div className="flex gap-2">
            <div>
              <img
                src={img}
                alt={title}
                width={"90px"}
                height={"90px"}
                className="object-contain"
              />
            </div>

            <div>
              <div className="flex flex-col gap-1">
                <span className="text-[#010101] font-inter text-[18px] font-medium">
                  {title}
                </span>
                <span className="text-[#010101] font-inter text-[16px] font-normal">
                  ₦{price}
                </span>
              </div>

              <div className="flex  gap-20 items-center">
                <div className="mt-2 w-[120px] h-[42px]  border border-[#E6E6E6] rounded-lg flex items-center justify-center gap-5">
                  <button className="" onClick={handleDecrease}>
                    -
                  </button>
                  <span>{quantity}</span>
                  <button className="" onClick={() => addToCart(id)}>
                    +
                  </button>
                </div>

                <div className="">
                  <button className="w-[30px] h-[30px] bg-[#F8F8F8] flex items-center justify-center">
                    <RiDeleteBinLine
                      color="#FF1616"
                      className="cursor-pointer"
                      onClick={handleDelete}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartItem;
