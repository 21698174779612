import React, { useState } from "react";
import img1 from "../assets/images/logo.svg";
import img2 from "../assets/images/img7.png";
import img3 from "../assets/images/google.png";
import img4 from "../assets/images/facebook1.png";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/validation";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
const Login = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const onSubmit = () => {
    alert("form succesfully submitted");
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <div className="w-full md:bg-[#404040] md:h-[100vh]">
      <div className="bg-white md:w-[1000px]  md:h-[500px]  relative  md:top-6 md:left-48 grid md:grid-cols-2 grid-cols-1">
        <div className="bg-red-700 hidden md:block">
          <img src={img2} alt="" className="" />
        </div>
        <div className="bg-white md:px-12 md:py-6 px-4 py-5">
          <Link to="/">
            <img src={img1} alt="logo" />
          </Link>

          <h3 className="text-[#010101] font-vastego font-semibold text-[30px] pt-8">
            Welcome Back 🥰
          </h3>
          <div>
            <form action="" className="mt-1" onSubmit={handleSubmit}>
              <div className="py-1">
                <label
                  htmlFor="email"
                  className="font-sans font-medium text-[14px] text-[#010101]"
                >
                  Email Address Email
                  <span className="text-[#EF4444] font-semibold">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError("email")}
                  className="pl-3 w-full bg-[#FCFCFD] h-[48px] rounded-[4px] border border-[#E9EBED] focus:outline-[#E9EBED]"
                />
              </div>
              <div className="py-1 relative">
                <label
                  htmlFor="password"
                  className="font-sans font-medium text-[14px] text-[#010101]"
                >
                  {" "}
                  Password{" "}
                  <span className="text-[#EF4444] font-semibold">*</span>
                </label>
                <input
                  type={`${show ? "text" : "password"}`}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="password"
                  autoComplete="off"
                  className={`pl-3 w-full bg-[#FCFCFD] h-[48px] rounded-[4px] border border-[#E9EBED] focus:outline-[#E9EBED] ${
                    errors.password && touched.password
                      ? "!border-red-400 border"
                      : ""
                  }`}
                />
                <span
                  onClick={toggleShow}
                  className="absolute right-3 top-12 cursor-pointer"
                >
                  {show ? (
                    <AiFillEye className="text-[#8E97A4]" />
                  ) : (
                    <AiFillEyeInvisible className="text-[#8E97A4]" />
                  )}
                </span>
                {errors.password && touched.password ? (
                  <p className="text-red-600 text-xs">{errors.password}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="flex justify-between items-center pt-4">
                <div className="flex gap-2 items-center">
                  <input
                    type="checkbox"
                    name="remember"
                    id="remember"
                 
                 className=""
                    htmlFor="remember"
                  />
                  <p className="font-sans text-[14px] font-normal text-[#333333] ">
                    Remember me
                  </p>
                </div>
                <Link
                  to="/passwordreset"
                  className="font-sans text-[14px] font-medium text-[#010101]"
                >
                  Forget Password?
                </Link>
              </div>

              <button className="w-full h-[48px] bg-[#0E7E80] font-sans font-semibold text-[14px] text-[#FCFCFD] rounded mt-4">
                Log in
              </button>
              <span className="font-sans font-normal text-[14px] text-[#4D4D4D] flex justify-center my-2 ">
                OR
              </span>
              <div className="flex justify-center items-center gap-4 ">
                <div className="w-[42px] h-[42px] bg-[#F2F2F3] rounded-full flex items-center justify-center">
                  <img src={img3} alt="" />
                </div>
                <div className="w-[42px] h-[42px] bg-[#F2F2F3] rounded-full flex items-center justify-center">
                  <img src={img4} alt="" />
                </div>
              </div>
              <div className="flex justify-center pt-4">
                <span className="font-sans text-[14px] font-normal text-[#333333] ">
                  Don&#x275C;t have account yet &#x3f;{" "}
                  <Link
                    to="/signup"
                    className="font-sans text-[14px] font-medium text-[#010101]"
                  >
                    Sign up
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
