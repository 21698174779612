import * as yup from "yup";

export const signupSchema = yup.object().shape({
  full_name: yup.string().required("Required"),
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters long")
    .required("Required"),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters long")
    .required("Required"),
});

export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .min(5, "Password must be at least 5 characters long")
    .required("Required"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Password must match")
    .required("Required"),
});

export const passwordReset = yup.object().shape({
  email: yup.string().email("Invalid email").required("Required"),
});
