import React   from "react";

const Categories = ({ onCategoryChange , onSortChange  }) => {


  const handleChange = (category) => {
    onCategoryChange(category);
  };

  const handleSortChange = (sortOption) => {
    onSortChange(sortOption);
  };
  return (
    <div className="">
      <div className="flex flex-col w-[225px] h-[300px] px-4 py-2 border rounded-lg border-[#E6EDFF] bg-[#FCFCFD] ">
        <span className="font-semibold text-[14px] text-[#1A1A1A] pb-2">
          Category
        </span>
        <div className="flex items-c{handleChange}enter gap-2 my-[6px]">
          <input
            type="radio"
            id="all"
            name="categories"
            onChange={() => handleChange("all")}
          />
          <label
            for="all"
            className="font-medium text-[14px] font-inter text-[#010101]"
          >
            All
          </label>
        </div>
        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="chair"
            name="categories"
            onChange={() => handleChange("chair")}
          />
          <label
            for="chair"
            className="font-normal text-[14px] text-[#666666] font-inter "
          >
            Chair
          </label>
        </div>
        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="table"
            name="categories"
            onChange={() => handleChange("table")}
          />
          <label
            for="table"
            className="font-normal text-[14px] text-[#666666] font-inter "
          >
            Table
          </label>
        </div>

        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="sofa"
            name="categories"
            onChange={() => handleChange("sofa")}
          />
          <label
            for="sofa"
            className="font-normal text-[14px] text-[#666666] font-inter "
          >
            Sofas
          </label>
        </div>
        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="bed"
            name="categories"
            onChange={() => handleChange("bed")}
          />
          <label
            for="bed"
            className="font-normal text-[14px] text-[#666666] font-inter "
          >
            Beds
          </label>
        </div>

        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="console"
            name="categories"
            onChange={() => handleChange("console")}
          />
          <label
            for="console"
            className="font-normal text-[14px] text-[#666666] font-inter "
          >
            Console
          </label>
        </div>

        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="office-furniture"
            name="categories"
            onChange={() => handleChange("furniture")}
          />
          <label
            for="office-furniture"
            className="font-normal text-[14px] text-[#666666] font-inter "
          >
            Office Furniture
          </label>
        </div>
      </div>

      <div className="flex flex-col w-[225px] h-[120px] px-4 py-2 border rounded-lg border-[#E6EDFF] bg-[#FCFCFD] mt-5">
        <span className="pb-2 font-semibold text-[14px] text-[#1A1A1A]">
          SORT
        </span>
        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="priceLowToHigh"
            name="sort"
         
            onChange={() => handleSortChange("priceLowToHigh")}
          />
          <label
            htmlFor="priceLowToHigh"
            className="font-normal text-[14px] text-[#666666] font-inter"
          >
            Price, low to high
          </label>
        </div>
        <div className="flex items-center gap-2 my-[6px]">
          <input
            type="radio"
            id="priceHighToLow"
            name="sort"
            onChange={() => handleSortChange("priceHighToLow")}
          />
          <label
            htmlFor="priceHighToLow"
            className="font-normal text-[14px] text-[#666666] font-inter"
          >
            Price, high to low
          </label>
        </div>
      </div>
    </div>
  );
};

export default Categories;
