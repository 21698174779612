import React from "react";
import { BsChevronRight } from "react-icons/bs";
const Feature = () => {
  return (
    <>
      <section className="relative bg-white w-full md:h-[1100px] md:py-12 md:px-24 py-8 px-8">
        <div className="text-center ">
          <h1 className="font-semibold  uppercase md:text-[32px] text-[24px]  text-[#010101] font-inter">
            featured category
          </h1>
          <p className="font-normal md:text-[18px] text-[16px] text-[#4D4D4D] font-inter">
            Get more, pay less: your ticket to furniture bliss!
          </p>
        </div>
        <div className="grid grid-cols-3 md:gap-4  pt-8">
          <div className="relative  md:w-[400px] md:h-[490px]  w-[220px] h-[332px] bg-cover bg-no-repeat image1 flex items-end">
            <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
              Home Decor
            </span>
          </div>
          <div className=" hidden  relative image2 md:flex items-end ">
            <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
              Office furniture
            </span>
          </div>

          <div className="grid grid-cols-2 grid-rows-2 md:gap-2 gap-0 relative md:left-0 left-[80px]">
            <div className="relative image3 flex items-end md:w-[203px] md:h-[240px] w-[80px] h-[166px] bg-[cover] bg-[no-repeat]">
              <span className="font-inter text-[14px] md:text-[16px] text-white font-semibold px-4 py-4">
                Table
              </span>
            </div>
            <div className="relative image4 flex items-end md:w-[203px] md:h-[240px] w-[82px] h-[166px] bg-[cover] bg-[no-repeat] left-4 ">
              <span className="font-inter text-[14px] md:text-[16px] text-white font-semibold px-4 py-4">
                Sofa
              </span>
            </div>
            <div className="relative image5 flex items-end md:w-[203px] md:h-[240px] w-[82px] h-[166px] bg-[cover] bg-[no-repeat]  ">
              <span className="font-inter text-[14px] md:text-[16px] text-white font-semibold px-4 py-4">
                Chair
              </span>
            </div>
            <div className="relative image6 flex items-end md:w-[203px] md:h-[240px] w-[82px] h-[166px] bg-[cover] bg-[no-repeat] left-4 ">
              <span className="font-inter text-[14px] md:text-[16px] text-white font-semibold px-4 py-4">
                Outdoor
              </span>
            </div>
          </div>
        </div>
        {/* <div className="grid grid-cols-3  gap-4  pt-8">
          <div className="relative  md:w-[400px] md:h-[490px]  w-[175px] h-[330px] bg-cover bg-no-repeat image1 flex items-end">
            <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
              Home Decor
            </span>
          </div>
          <div className="relative image2 flex items-end ">
            <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
              Office furniture
            </span>
          </div>

          <div className="grid grid-cols-2 grid-rows-2 gap-2">
            <div className="relative image3 flex items-end ">
              <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
                Table
              </span>
            </div>
            <div className="relative image4 flex items-end ">
              <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
                Sofa
              </span>
            </div>
            <div className="relative image5 flex items-end ">
              <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
                Chair
              </span>
            </div>
            <div className="relative image6 flex items-end ">
              <span className="font-inter text-[16px] text-white font-semibold px-4 py-4">
                Outdoor
              </span>
            </div>
          </div>
        </div> */}
        <div class="hero-image bg-cover bg-center bg-no-repeat md:h-[320px] md:w-[1280px] w-[358px] h-[320px] relative  flex rounded-lg mt-12 ">
          <div className="md:w-[430px] md:h-[250px] w-[328px]  h-[134px] md:py-6  mx-[auto] my-[auto]">
            <h1 className="text-[#fff] font-urbanist md:px-2 px-1 uppercase md:leading-[40px]  font-semibold text-[23px] md:text-[30px] text-center">
              build your dream <br /> room to real life now !
            </h1>

            <div className="flex justify-center items-center my-4">
              <button className="w-[200PX] h-[48px] bg-[#0E7E80] rounded-[4px] text-[14px] font-semibold text-[#FCFCFD]">
                EXPLORE MORE
              </button>
              <BsChevronRight
                size={16}
                color="white"
                className="relative top-[0.5px] right-8"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
