import React, { useContext, useState } from "react";
import img from "../assets/images/logo.svg";
import cart from "../assets/images/cart.png";
import { Link, NavLink, Outlet } from "react-router-dom";

import { CiSearch } from "react-icons/ci";
import { ShopContext } from "../context/Shop-context";
import { HiOutlineMenuAlt1 } from "react-icons/hi";
import { AiOutlineClose } from "react-icons/ai";
import Footer from "./Footer";

const Navbar = () => {
  const { cartItemCount, isItemAddedToCart } = useContext(ShopContext);
  const [showNavLinks, setShowNavLinks] = useState(false);

  const links = [
    {
      id: 1,
      title: "Home",
      url: "/",
    },
    {
      id: 2,
      title: "Shop",
      url: "/shop",
    },
    {
      id: 3,
      title: "Contact",
      url: "/contact",
    },

    // {
    //   id: 5,
    //   title: "Login",
    //   url: "/login",
    // },
    // {
    //   id: 6,
    //   title: "Register",
    //   url: "/register",
    // },
  ];
  return (
    <>
      <div className="relative flex justify-between items-center gap-3 md:px-16 md:py-10 px-3  bg-[#fff] h-[96px]">
        <div className="md:hidden">
          {showNavLinks ? (
            <AiOutlineClose
              onClick={() => setShowNavLinks(false)}
              className="text-[24px] cursor-pointer"
            />
          ) : (
            <HiOutlineMenuAlt1
              onClick={() => setShowNavLinks(true)}
              className="text-[24px] cursor-pointer"
            />
          )}
        </div>
        <div>
          <Link to="/">
            <img src={img} className="w-[60px] md:w-[100px]" alt="" />
          </Link>
        </div>

        <div className="hidden md:flex space-x-10 items-center">
          {links.map((link) => (
            <NavLink
              key={link.id}
              to={link.url}
              className="text-[16px] font-sans font-medium  text-[#010101]"
            >
              {link.title}
            </NavLink>
          ))}
        </div>
        <div className="flex items-center md:gap-8 ">
          <div className="flex items-center w-[90px] gap-2 border-none">
            <CiSearch className="w-[20px] border-none h-5 "  size={16}/>
            <input
              className="input hidden md:block w-[60px]   h-5 font-sans font-medium text-[14px] text-[#010101] border-none focus:outline-none"
              placeholder="search"
            />
          </div>
          <div className="relative left-5">
            <Link to="/cart" className="flex items-center">
              <img
                src={cart}
                alt="cart"
                style={{ color: isItemAddedToCart ? "red" : "" }}
              />
              <div className="w-[20px] h-[20px] bg-[#EE3A37] rounded-full flex justify-center items-center relative left-[-13px] top-[-5px]">
                <p className=" text-white text-[12px] font-medium font-inter">
                  {cartItemCount}
                </p>
              </div>
            </Link>
          </div>

          <div className="flex gap-1 w-[171px] items-center pl-6 md:pl-0">
            <Link to="/login">
              <button className="text-[14px] font-medium font-sans text-[#010101]">
                LOGIN
              </button>
            </Link>
            |
            <Link to="/signup">
              <button className="text-[14px] font-medium font-sans text-[#010101]">
                REGISTER
              </button>
            </Link>
          </div>
        </div>

        {/* Mobile Nav Links */}
        {showNavLinks && (
          <div className="md:hidden absolute  inset-0 bg-white text-[white] top-16 w-full h-[150px]   z-50">
            <div className="flex flex-col absolute left-5   uppercase">
              {links.map((link) => (
                <NavLink
                  key={link.id}
                  to={link.url}
                  className="text-[16px] font-inter font-semibold  text-[#010101] py-2"
                >
                  {link.title}
                </NavLink>
              ))}
            </div>
          </div>
        )}
      </div>

    <main>
      <Outlet/>
    </main>
    <Footer/>
    </>
  );
};

export default Navbar;
