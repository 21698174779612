import React, { useEffect } from "react";
import map from "../assets/images/Map.png";
import { BsChevronRight } from "react-icons/bs";
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="w-full lg:h-[700px] md:h-[1200px]  md:px-16 md:py-8 py-6 px-3.5 bg-white">
      <div className="grid grid-cols-1  gap-10 md:gap-0 lg:grid-cols-2  md:px-6">
        <div className="md:w-[576px] md:h-[554px] w-[358px] h-[550px] border border-[#E1DEDB] px-4 py-4 rounded-lg">
          <h1 className="uppercase font-inter font-semibold text-[20px] lg:text-[24px] text-[#010101] ">
            Contact Us
          </h1>

          <div className="mt-4">
            <form>
              <div class="mb-4">
                <label
                  for="name"
                  class="block text-[14px] md:text-[16px] font-medium font-inter"
                >
                  Full Name *
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="David Flada"
                  class="bg-[#FCFCFD] h-[48px ]  mt-2 p-2 w-full border border-[#E9EBED] focus:outline-none  rounded"
                />
              </div>
              <div class="mb-4">
                <label
                  for="email"
                  class="block text-[14px] md:text-[16px] font-medium  font-inter"
                >
                  Email *
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  class="bg-[#FCFCFD] h-[48px ]  mt-2 p-2 w-full border border-[#E9EBED] focus:outline-none  rounded"
                />
              </div>
              <div class="mb-4">
                <label
                  for="email"
                  class="block text-[14px] md:text-[16px] font-medium font-inter"
                >
                  Message *
                </label>
                <textarea
                  id="message"
                  name="message"
                  rows="4"
                  placeholder="Type here"
                  class="bg-[#FCFCFD] h-[48px ]  mt-2 p-2 w-full border border-[#E9EBED] focus:outline-none  rounded"
                ></textarea>
              </div>
              <div className="flex ">
                <button className="w-full ml-2 h-[48px] rounded-lg bg-[#0E7E80]  text-[14px] font-medium font-inter text-white leading-5">
                  Send Message
                </button>
                <BsChevronRight
                  size={16}
                  color="white"
                  className="relative md:top-[17px] top-3.5 md:right-48 right-16"
                />
              </div>
            </form>
          </div>
        </div>

        <div className="md:w-[576px] md:h-[554px] w-[358px] h-[588px] border border-[#E1DEDB] px-4 py-4 rounded-lg">
          <img src={map} alt="" />

          <div className="grid md:grid-cols-2 grid-rows-4 gap-2 my-3">
            <div>
              <div className="flex flex-col mt-3">
                <span className="font-inter font-semibold text-[16px] text-[#010101]">
                  Store Address :
                </span>
                <span className="font-inter font-normal text-[14px] text-[#666666]">
                Shop 85 Amosun
Shopping complex itoku
Abeokuta Ogunstate
                </span>
              </div>
            </div>

            <div>
              <div className="flex flex-col mt-3">
                <span className="font-inter font-semibold text-[16px] text-[#010101]">
                  TIMING :
                </span>
                <div className="flex flex-col gap-1">
                  <span className="font-inter font-normal text-[14px] text-[#666666]">
                    Monday - Friday : 10 am to 6 pm
                  </span>
                  <span className="font-inter font-normal text-[14px] text-[#666666]">
                    Saturday : 10 am to 6 pm
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div className="flex flex-col mt-3">
                <span className="font-inter font-semibold text-[16px] text-[#010101]">
                  CALL US :
                </span>
                <span className="font-inter font-normal text-[14px] text-[#666666]">
                +234 9019302697
                </span>
              </div>
            </div>

            <div>
              <div className="flex flex-col md:mt-3">
                <span className="font-inter font-semibold text-[16px] text-[#010101]">
                  EMAIL :
                </span>

                <span className="font-inter font-normal text-[14px] text-[#666666]">
                wyntlimited@gmail.com
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
