import React from "react";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import img1 from "../assets/images/image1.png";
import img2 from "../assets/images/image2.png";
import img3 from "../assets/images/image3.png";
import img4 from "../assets/images/image4.png";
import img5 from "../assets/images/image5.png";
import img6 from "../assets/images/image6.png";
import img7 from "../assets/images/image7.png";
import img8 from "../assets/images/image8.png";
const products = [
  {
    id: 1,
    img: img1,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 2,
    img: img2,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 3,
    img: img3,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 4,
    img: img4,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 5,
    img: img5,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 6,
    img: img6,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 7,
    img: img7,
    title: "Product Title",
    price: "20000",
    url: "",
  },
  {
    id: 8,
    img: img8,
    title: "Product Title",
    price: "20000",
    url: "",
  },
];

const Products = () => {
  return (
    <div className="relative bg-white w-full md:h-[1300px]  md:py-10 md:px-24 py-8 px-8">
      <div className="text-center">
        <h1 className="font-semibold md:text-[36px] text-center  text-[32px] text-[#010101] font-inter">
          FRESH ARRIVAL
        </h1>
        <p className="font-normal text-[18px] text-[#4D4D4D] font-inter">
          Fresh styles, new beginnings: discover our latest arrivals!
        </p>
      </div>

      <div className="grid md:grid-cols-4 grid-cols-1 md:gap-10 gap-6 py-10">
        {products.map((product) => (
          <Link to={`/productdetails/${product.id}`} key={product.id}>
            <div>
              <img
                src={product.img}
                alt=""
                className="w-[358px] h-[368px] md:[302px]"
              />
              <p className="font-semibold text-[21px] text-[#010101] font-inter pt-4">
                {product.title}
              </p>
              <span className="font-medium text-[16px] text-[#4D4D4D] font-inter">
                ₦{product.price}
              </span>
            </div>
          </Link>
        ))}
      </div>

      <div className="flex justify-center my-1">
        <button className="w-[160px] h-[48px] bg-[#0E7E80] rounded-[4px] text-[14px] font-semibold text-[#FCFCFD] font-inter">
          VIEW ALL
        </button>
        <BsChevronRight
          size={16}
          color="white"
          className="relative top-[16px] right-8"
        />
      </div>
    </div>
  );
};

export default Products;
