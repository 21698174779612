import React from "react";
import Slider from "./Slider";

export const Testimonial = () => {
  return (
    <section className="relative bg-white w-full md:h-[400px] py-6 md:px-16 px-8">
      <div className="text-center ">
        <h1 className="font-semibold  uppercase md:text-[32px] text-[24px] text-[#010101] font-inter">
          customer review
        </h1>
        <p className="font-normal md:text-[18px] text-[16px]  text-[#4D4D4D] font-inter pt-2">
          Discover the power of customer satisfaction through their inspiring
          stories
        </p>
      </div>

      <div className="px-0 py-10">
        <Slider />
      </div>
    </section>
  );
};
