

import { Route, Routes } from "react-router-dom";

import Shop from "./pages/Shop";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./auth/Login";
import SignUp from "./auth/SignUp";
import PasswordReset from "./auth/PasswordReset";
import PasswordSet from "./auth/PasswordSet";
import NotFound from "./pages/NotFound";
import Navbar from "./Layout/Header";
import ProductDetails from "./productDetails/ProductDetails";
import Cart from "./productDetails/Cart";
import ShopContextProvider from "./context/Shop-context";

function App() {
  return (
    <>
      <ShopContextProvider>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index element={<Home />}></Route>
            <Route path="shop" element={<Shop />}></Route>
            <Route path="contact" element={<Contact />}></Route>
            <Route path="login" element={<Login />}></Route>
            <Route path="signup" element={<SignUp />}></Route>
            <Route path="passwordreset" element={<PasswordReset />}></Route>
            <Route path="passwordset" element={<PasswordSet />}></Route>
            <Route
              path="productdetails/:id"
              element={<ProductDetails />}
            ></Route>
            <Route path="cart" element={<Cart />}></Route>
            <Route path="*" element={<NotFound />}></Route>
          </Route>
        </Routes>
      
    
      </ShopContextProvider>
    </>
  );
}

export default App;
