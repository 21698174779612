import { Link } from "react-router-dom";
import React, { useState } from "react";
import img1 from "../assets/images/logo.svg";
import img2 from "../assets/images/img7.png";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../schemas/validation";

const PasswordSet = () => {
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((prev) => !prev);
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const onSubmit = () => {
    alert("form succesfully submitted");
  };

  const {
    handleChange,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    errors,
    touched,
  } = useFormik({
    initialValues,
    validationSchema: resetPasswordSchema,
    onSubmit,
  });

  const getError = (key) => {
    return touched[key] && errors[key];
  };
  return (
    <div className="w-full md:bg-[#404040] md:h-[100vh]">
      <div className="bg-white md:w-[1000px] h-[500px]  relative md:top-6 md:left-48 grid md:grid-cols-2 grid-cols-1">
        <div className="bg-red-700 hidden md:block">
          <img src={img2} alt="" className="" />
        </div>
        <div className="bg-white md:px-12 md:pt-10 px-4 pt-2">
          <Link to="/">
            <img src={img1} alt="logo" />
          </Link>

          <h3 className="text-[#010101] font-vastego font-semibold text-[30px] pt-5">
            Set new Password 🔒
          </h3>
          <p className="font-sans font-normal text-[14px] text-[#666666] py-2">
            Enter your desired password must be more than 8 characters.
          </p>
          <div>
            <form action="" className="mt-1" onSubmit={handleSubmit}>
              <div className="pt-1 relative">
                <label
                  htmlFor="newPassword"
                  className="font-sans font-medium text-[14px] text-[#010101]"
                >
                  New Password{" "}
                  <span className="text-[#EF4444] font-semibold">*</span>
                </label>
                <input
                  type={`${show ? "text" : "password"}`}
                  id="password"
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className={` w-full pl-3 bg-[#FCFCFD] h-[48px] rounded-[4px] border border-[#E9EBED] my-2 focus:outline-[#E9EBED] ${
                    errors.password && touched.password
                      ? "!border-red-400 border"
                      : ""
                  }`}
                />
                <span
                  onClick={toggleShow}
                  className="absolute right-3 top-12 cursor-pointer"
                >
                  {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                </span>
                {errors.password && touched.password ? (
                  <p className="text-red-600 text-xs">{errors.password}</p>
                ) : (
                  ""
                )}
              </div>
              <div className="py-1 relative">
                <label
                  htmlFor="confirmPassword"
                  className="font-sans font-medium text-[14px] text-[#010101]"
                >
                  Confirm Password{" "}
                  <span className="text-[#EF4444] font-semibold">*</span>
                </label>
                <input
                  type={`${show ? "text" : "confirmPassword"}`}
                  id="confirmPassword"
                  name="confirmPassword"
                  value={values.confirmPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="off"
                  className={` w-full pl-3 bg-[#FCFCFD] h-[48px] rounded-[4px] border border-[#E9EBED] my-2 focus:outline-[#E9EBED] ${
                    errors.confirmPassword && touched.confirmPassword
                      ? "!border-red-400 border"
                      : ""
                  }`}
                />
                <span
                  onClick={toggleShow}
                  className="absolute right-3 top-12 cursor-pointer"
                >
                  {show ? <AiFillEye /> : <AiFillEyeInvisible />}
                </span>
                {errors.confirmPassword && touched.confirmPassword ? (
                  <p className="text-red-600 text-xs">
                    {errors.confirmPassword}
                  </p>
                ) : (
                  ""
                )}
              </div>
              <button className="w-full h-[48px] bg-[#0E7E80] font-sans font-semibold text-[14px] text-[#FCFCFD] rounded mt-4">
                Reset Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PasswordSet;
