import React from "react";
import Hero from "../components/Hero";
import Products from "../components/Products";
import Feature from "../components/Feature";
import { Testimonial } from "../components/Testimonial";

const Home = () => {
 
  return (
    <div>
      <Hero />
      <Products />
      <Feature />
      <Testimonial />
    </div>
  );
};

export default Home;
